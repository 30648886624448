<template>
  <div
    class="expande-horizontal centraliza"
    style="height: 100vh; background-color: #333;"
  >
    <v-flex xs12 md8>
      <div class="expande-horizontal wrap">
        <v-card
          color=""
          class="expande-horizontal wrap"
          style="min-height: 27vh;"
        >
          <div class="expande-horizontal centraliza wrap">
            <v-flex class="pa-6" xs12>
              <div
                style="min-height: 10vh;"
                class="expande-horizontal pa-6 centraliza"
              >
                <v-progress-circular
                  v-if="loading === 'loading'"
                  indeterminate
                  size="100"
                  color="black"
                >
                </v-progress-circular>
                <v-avatar v-else size="100" color="orange">
                  <v-icon size="50" color="black">
                    mdi-close
                  </v-icon>
                </v-avatar>
              </div>
            </v-flex>
            <v-flex class="pa-6" xs12>
              <v-window v-model="step">
                <v-window-item :value="'1'">
                  <div
                    transition="fade"
                    class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                  >
                    <v-flex class="pa-5 mt-12 pb-0" xs12>
                      <div class="expande-horizontal centraliza column">
                        <h2 class="fonte-bold">
                          Validando acesso
                        </h2>
                        <v-flex class="pa-3 pr-6 pl-6" xs12>
                          <span class="fonte red--text">{{ message }}</span>
                        </v-flex>
                      </div>
                    </v-flex>
                  </div>
                </v-window-item>
              </v-window>
            </v-flex>
          </div>
        </v-card>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: "loading",
      message: "Aguarde enquanto validamos seu acesso...",
      step: "1"
    };
  },
  props: ["id"],
  methods: {
    ...mapActions(["authWithRev"]),
    setLoading(state) {
      this.loading = state;
    }
  },
  mounted() {
    this.authWithRev(this.id).catch(() => {
      this.message = "Token inválido, tente novamente.";
      this.loading = "error";
    });
  }
};
</script>

<style>
.my-clipper {
  width: 250px;
  height: 250px;
}
</style>
